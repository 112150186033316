import { ActionFunction, LoaderFunctionArgs, json, redirect } from "@remix-run/node";
import { Form, useActionData, useLoaderData, useNavigation, useOutletContext } from "@remix-run/react";
import { useEffect, useRef, useState } from "react";
import Logo from "~/components/logo";
import { Input } from "~/components/ui/input";
import { isValidAccessCode } from "~/db.server/isValidAccessCode";
import { RootHandle } from "~/root";
import { auth } from "~/services/auth.server";
import { getSession, destroySession } from "~/services/session.server";
import { cn } from "~/utils";

export async function loader({ request }: LoaderFunctionArgs) {
  const access_code = new URL(request.url).searchParams.get("access_code");
  const userMaybe = await auth.isAuthenticated(request);

  return json({ access_code, isAuthenticated: userMaybe !== null });
}

export const action: ActionFunction = async ({ request }) => {
  const session = await getSession(request.headers.get("Cookie"));
  const formData = new URLSearchParams(await request.text());
  const access_code = formData.get("access_code");
  const isValidCode = await isValidAccessCode(access_code);

  if (!isValidCode) {
    return json({ error: "Invalid access code" }, { status: 400 });
  }

  // let's reuse index route auth logic but destory the session on the way
  return redirect(`/?access_code=${access_code}`, {
    headers: {
      "Set-Cookie": await destroySession(session),
    },
  });
};

export default function Index() {
  const { access_code, isAuthenticated } = useLoaderData<typeof loader>();
  const formAction = useActionData<typeof action>();
  const { hideAuthorizedLinks, showAuthorizedLinks } = useOutletContext<RootHandle>();
  const { state } = useNavigation();
  const [error, setError] = useState<string | null>(formAction?.error || null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = () => {
    setError(null);
    if (inputRef.current) {
      inputRef.current.setCustomValidity("");
    }
  };

  useEffect(() => {
    hideAuthorizedLinks();
    return () => showAuthorizedLinks();
  }, [hideAuthorizedLinks, showAuthorizedLinks]);

  useEffect(() => {
    if (formAction?.error && inputRef.current) {
      inputRef.current.setCustomValidity(formAction.error);
      inputRef.current.reportValidity();
      setError(formAction.error);
    }
  }, [formAction]);

  return (
    <Form
      method="post"
      className=" flex grow flex-col items-center justify-center gap-10 px-5 text-center text-white lg:max-w-[110dvh] lg:grid-cols-[auto_1fr] lg:grid-rows-[1fr_auto]"
    >
      <Logo className="w-32" />
      <h1 className="text-3xl font-light">
        {!isAuthenticated ? "Please enter your access code" : "Confirm changing saved code to the new one below:"}
      </h1>

      <div className="flex w-full items-end	 justify-between gap-5">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="access_code" className="w-full  flex-shrink ">
          <Input
            className={cn("w-full  text-center text-2xl ", {
              "border-keenError text-keenError": !!error,
            })}
            defaultValue={access_code ?? ""}
            placeholder="hf71…"
            data-testid="access-code"
            spellCheck="false"
            autoCorrect="off"
            autoComplete="current-password"
            name="access_code"
            id="access_code"
            ref={inputRef}
            onChange={handleChange}
          />
        </label>

        <button
          className={cn("btn-CTA whitespace-nowrap px-4 py-3 text-xl text-white", {
            "animate-[errorShake_0.6s_ease-in-out_1]": !!error && state === "idle",
            "animate-pulse": state === "submitting",
          })}
        >
          Get in!
        </button>
      </div>
    </Form>
  );
}
